import React from "react";
import Image from "../../images/aws-certified-cloud-practitioner.png";

const AWSCloudPractitioner = (e) => {
  return (
    <div className="main-container">
      <img src={Image} width="250px" height="250px" alt="" />
    </div>
  );
};

export default AWSCloudPractitioner;
