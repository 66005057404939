export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "About Me",
  headLine: "Father, Veteran, Engineer",
  description:
    "I am a proud father of two, a United States Army Veteran, and a Software Engineering alumnus of Iowa State University. Throughout my life, I have nurtured a deep fascination with technology, reflecting on cherished childhood memories of exploring file structures, adjusting settings, and overcoming challenges that occasionally led to resetting the operating system. My educational journey in Software Engineering at Iowa State University and industry experience has shaped my understanding and passion for this field.",
  buttonLabel: "See my work",
  to: "projects",
  imgStart: true,
  img: require("../../images/about.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};
