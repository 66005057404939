import React from "react";
import Image from "../../images/aws-certified-developer-associate.png";

const AWSDevAss = (e) => {
  return (
    <div className="main-container">
      <img src={Image} width="250px" height="250px" alt="" />
    </div>
  );
};

export default AWSDevAss;
