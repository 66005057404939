import React from "react";
import { ButtonScroll } from "../ButtonScroll";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  SubTitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headLine,
  darkText,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  to,
}) => {
  return (
    <InfoContainer lightBg={lightBg} id={id}>
      <InfoWrapper>
        <InfoRow imgStart={imgStart}>
          <Column1>
            <TextWrapper>
              <TopLine>{topLine}</TopLine>
              <Heading lightText={lightText}>{headLine}</Heading>
              <SubTitle darkText={darkText}>
                <br />
                I am a dedicated father of two, a proud United States Army
                Veteran, and an alumnus of Iowa State University's esteemed
                Software Engineering program. Since childhood, I have been
                captivated by technology and its transformative potential,
                driving my passion for innovative solutions and the
                ever-evolving tech world.
                <br />
                <br />
                My role as a father has instilled in me a deep sense of
                responsibility, patience, and the ability to prioritize
                effectively. Being there for my children, and supporting their
                growth and well-being, has taught me the value of dedication and
                commitment.
                <br />
                <br />
                Serving in the United States Army has been a source of great
                pride and has profoundly shaped my character. The military
                instilled discipline, resilience, and the ability to thrive in
                high-pressure environments. The experiences and lessons learned
                during my service have reinforced my dedication to teamwork,
                adaptability, and problem-solving.
                <br />
                <br />
                During my transformative education at Iowa State University, I
                embraced software engineering, fueling my passion and ambition
                to create innovative solutions. The comprehensive curriculum
                equipped me with a deep understanding of diverse concepts,
                theories, and practical applications, enabling confident and
                efficient problem-solving.
                <br />
                <br />
                These experiences shaped me into a humble yet ambitious
                engineer, ready to make meaningful contributions and drive
                positive technological change.
              </SubTitle>
              <BtnWrap>
                <ButtonScroll
                  to={to}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={0}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                >
                  {buttonLabel}
                </ButtonScroll>
              </BtnWrap>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={img} alt={alt} />
            </ImgWrap>
          </Column2>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default InfoSection;
