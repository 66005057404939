import React, { useState } from "react";
import { FaGithub } from "react-icons/fa";
import { TbButterfly } from "react-icons/tb";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  Heading,
  SubTitle,
  ImgWrap,
  SocialIconLink,
  FeaturedProjectDiv,
  FeaturedProjectTitle,
  FeaturedProjectText,
} from "./ProjectElements";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import EducationData from "./educationData";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ProjectsSection = ({
  lightBg,
  id,
  imgStart,
  lightText,
  headLine,
  darkText,
  description1,
  description2,
}) => {
  const [educationItems] = useState(EducationData);

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <div data-aos="fade-left">
          <InfoWrapper>
            <InfoRow imgStart={imgStart}>
              <Column1>
                <TextWrapper>
                  <Heading lightText={lightText}>{headLine}</Heading>
                  <SubTitle darkText={darkText}>{description1}</SubTitle>
                  <SubTitle darkText={darkText}>{description2}</SubTitle>
                  {educationItems.map((education) => (
                    <Accordion key={education.id} sx={{ fontSize: "14px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={education.id}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography variant="text">
                            {education.title}
                          </Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails sx={{ textAlign: "center" }}>
                        <Typography>{education.description}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </TextWrapper>
              </Column1>
              <Column2>
                <ImgWrap>
                  <p style={{ color: "black" }}>View my GitHub here:</p>
                  <SocialIconLink
                    area-label="Github"
                    href="https://github.com/AnthonyMazzie"
                    target="_blank"
                  >
                    <FaGithub />
                  </SocialIconLink>
                </ImgWrap>
              </Column2>
            </InfoRow>
          </InfoWrapper>
          <FeaturedProjectDiv>
            <FeaturedProjectTitle>Featured project:</FeaturedProjectTitle>
            <br />
            <FeaturedProjectText style={{ color: "black" }}>
              The Unified Butterfly Recorder (UBR) is a Progressive Web App
              (PWA) developed by a team of 7 software engineering students from
              Iowa State University in collaboration with the Entomology staff
              at Reiman Gardens. It provides butterfly researchers and
              enthusiasts with a convenient tool to record butterfly sightings
              in the field.
              <br />
              <br />
              UBR utilizes technology and community participation to gather
              valuable data for climate change research and engage individuals
              in butterfly conservation. It collects comprehensive data on
              butterfly populations' responses to environmental factors,
              including weather and user-entered information. This data
              contributes to understanding climate change's impact on butterfly
              habitats, identifying vulnerable species, and encouraging
              individuals to participate in climate change research and
              conservation efforts.
            </FeaturedProjectText>
            <br />
            <a
              href="https://www.unifiedbutterflyrecorder.com"
              target="_blank"
              rel="noreferrer"
            >
              <TbButterfly size={65} />
              <br />
              Unified Butterfly Recorder
            </a>
          </FeaturedProjectDiv>
        </div>
      </InfoContainer>
    </>
  );
};

export default ProjectsSection;
