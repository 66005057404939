const engineeringCourses = [
  {
    id: 1,
    title: "Digital Logic",
    description:
      "CPR E 281: Number systems and representation. Boolean algebra and logic minimization. Combinational and sequential logic design. Arithmetic circuits and finite state machines. Use of programmable logic devices. Introduction to computer-aided schematic capture systems, simulation tools, and hardware description languages. Design of simple digital systems. *Source: Iowa State University Catalog",
  },
  {
    id: 2,
    title: "Problem Solving in Software Engineering",
    description:
      "SE 185: Introduction to software engineering and computer programming. Systematic thinking process for problem solving in the context of software engineering. Group problem solving. Solving software engineering problems and presenting solutions through computer programs, written documents and oral presentations. Introduction to principles of programming, software design, and extensive practice in design, writing, running, debugging, and reasoning about programs. *Source: Iowa State University Catalog",
  },
  {
    id: 3,
    title: "Object-Oriented Programming",
    description:
      "COM S 227: Computer programming using objects as the mechanism for modularity, abstraction, and code reuse. Instance variables, methods, and encapsulation. Review of control structures for conditionals and iteration. Developing algorithms on strings, arrays, and lists. Recursion, searching, and sorting. Text parsing and file I/O. Interfaces, inheritance, polymorphism, and abstract classes. Exception handling. Tools for unit testing and debugging. Emphasis on a disciplined approach to specification, code development, and testing. *Source: Iowa State University Catalog",
  },
  {
    id: 4,
    title: "Data Structures and Algorithms",
    description:
      "COM S 228: An object-oriented approach to data structures and algorithms. Object-oriented analysis, design, and programming, with emphasis on data abstraction, inheritance and subtype polymorphism, and generics. Abstract data type specification and correctness. Collections including lists, stacks, queues, trees, heaps, maps, hash tables, and graphs. Big-O notation and algorithm analysis. Searching and sorting. Graph search and shortest path algorithms. Emphasis on object-oriented design, writing and documenting medium-sized programs. This course is designed for majors. *Source: Iowa State University Catalog",
  },
  {
    id: 5,
    title: "Embedded Systems 1",
    description:
      "CPR E 288: Embedded C programming. Interrupt handling. Memory mapped I/O in the context of an application. Elementary embedded design flow/methodology. Timers, scheduling, resource allocation, optimization, state machine based controllers, real time constraints within the context of an application. Applications laboratory exercises with embedded devices. *Source: Iowa State University Catalog",
  },
  {
    id: 6,
    title: "Principles of Programming Languages",
    description:
      "COM S 342: Study of concepts in programming languages, especially functional programming concepts. Overview of major programming paradigms, their relationship, and tradeoffs among paradigms enabling sound choices of programming language for application-specific development. Programming projects. *Source: Iowa State University Catalog",
  },
  {
    id: 7,
    title: "Software Development Practices",
    description:
      "COM S 309: A practical introduction to methods for managing software development. Process models, requirements analysis, structured and object-oriented design, coding, testing, maintenance, cost and schedule estimation, metrics. Programming projects. *Source: Iowa State University Catalog",
  },
  {
    id: 8,
    title: "Construction of User Interfaces",
    description:
      "COM S 319: Overview of user interface design. Evaluation and testing of user interfaces. Review of principles of object orientation, object oriented design and analysis using UML in the context of user interface design. Design of windows, menus and commands. Developing Web and Windows-based user-interfaces. Event-driven programming. Introduction to Frameworks and APIs for the construction of user interfaces. *Source: Iowa State University Catalog",
  },
  {
    id: 9,
    title:
      "Introduction to Computer Architecture and Machine-Level Programming",
    description:
      "COM S 321: Introduction to computer architecture and organization. Emphasis on evaluation of performance, instruction set architecture, datapath and control, memory-hierarchy design, and pipelining. Assembly language programming. *Source: Iowa State University Catalog",
  },
  {
    id: 10,
    title: "Database Management Systems",
    description:
      "COM S 363: Relational, object-oriented, semistructured and query languages. SQL, XML, and NO-SQL. Database design using entity-relationship model, data dependencies, and relational database design. Application development in SQL-like languages and general purpose host languages with application program interfaces and a commonly used Object Relational Mapping framework. Web application development. Programming Projects. *Source: Iowa State University Catalog",
  },
  {
    id: 11,
    title: "Advanced Programming Techniques",
    description:
      "COM S 327: Object-oriented programming experience using a language suitable for exploring advanced topics in programming. Topics include memory management, parameter passing, inheritance, compiling, debugging, and maintaining programs. Significant programming projects. *Source: Iowa State University Catalog",
  },
  {
    id: 12,
    title: "Operating Systems: Principles and Practice",
    description:
      "CPR E 308: Operating system concepts, processes, threads, synchronization between threads, process and thread scheduling, deadlocks, memory management, file systems, I/O systems,security, Linux-based lab experiments. *Source: Iowa State University Catalog",
  },
  {
    id: 13,
    title: "Introduction to Software Testing",
    description:
      "SE 317: Basic principles and techniques for software testing. Test requirements and management. Test design techniques, evaluation metrics, model-based testing, unit testing, system and integration testing. Software testing tools and programming projects. *Source: Iowa State University Catalog",
  },
  {
    id: 14,
    title: "Software Architecture and Design",
    description:
      "SE 339: Modeling and design of software at the architectural level. Architectural styles. Basics of model-driven architecture. Object-oriented design and analysis. Iterative development and unified process. Design patterns. Design by contract. Component based design. Product families. Measurement theory and appropriate use of metrics in design. Designing for qualities such as performance, safety, security, reliability, reusability, etc. Analysis and evaluation of software architectures. Introduction to architecture definition languages. Basics of software evolution, reengineering, and reverse engineering. Case studies. Introduction to distributed system software. *Source: Iowa State University Catalog",
  },
  {
    id: 15,
    title: "Introduction to the Design and Analysis of Algorithms",
    description:
      "COMS 311: Basic techniques for design and analysis of algorithms. Sorting, searching, graph algorithms, string matching, and NP-completeness. Design techniques such as dynamic programming, divide and conquer, greedy method, and approximation. Asymptotic, worst-case, average-case and amortized analyses. Topics from advanced data structures such as balanced trees and hashing. Programming projects. *Source: Iowa State University Catalog",
  },
  {
    id: 16,
    title: "Software Requirements Engineering",
    description:
      "SE 409: The requirements engineering process including elicitation, requirements analysis fundamentals, requirements specification and communication, and requirements evaluation. Modeling of functional and nonfunctional requirements, traceability, and requirements change management. Case studies and software projects. *Source: Iowa State University Catalog",
  },
  {
    id: 17,
    title: "Software Analysis and Verification for Safety and Security",
    description:
      "SE 421: Significance of software safety and security; various facets of security in cyber-physical and computer systems; threat modeling for software safety and security; and categorization of software vulnerabilities. Software analysis and verification: mathematical foundations, data structures and algorithms, program comprehension, analysis, and verification tools; automated vs. human-on-the-loop approach to analysis and verification; and practical considerations of efficiency, accuracy, robustness, and scalability of analysis and verification. Cases studies with application and systems software; evolving landscape of software security threats and mitigation techniques. Understanding large software, implementing software analysis and verification algorithms. *Source: Iowa State University Catalog",
  },
  {
    id: 18,
    title: "Senior Design 1",
    description:
      "SE 491: Preparing for entry to the workplace. Selected professional topics. Use of technical writing skills in developing project plan and design report; design review presentation. First of two-semester team-oriented, project design and implementation experience. *Source: Iowa State University Catalog",
  },
  {
    id: 19,
    title: "Computer Networking and Data Communications",
    description:
      "CPR E 492: Modern computer networking and data communications concepts. OSI reference model, TCP/IP architecture. Sockets programming. Protocols at the physical layer, data link layer, network layer, transport layer, and application layer. Software-defined networking. *Source: Iowa State University Catalog",
  },
  {
    id: 20,
    title: "Senior Design 2",
    description:
      "SE 492: Second semester of a team design project experience. Emphasis on the successful implementation and demonstration of the design completed in E E 491 or Cpr E 491 and the evaluation of project results. Technical writing of final project report; oral presentation of project achievements; project poster. *Source: Iowa State University Catalog",
  },
];
export default engineeringCourses;
