import React from "react";
import Image from "../images/newlog.svg";

const Logo = (e) => {
  return (
    <div className="main-container">
      <img src={Image} alt="" />
    </div>
  );
};

export default Logo;
