export const projectsObj = {
  id: "projects",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headLine: "Because learning by doing is the best way",
  description1:
    "The foundation of my education was gained at Iowa State University, where I had the opportunity to immerse myself in various classes spanning Computer Engineering, Software Engineering, and Computer Science. These courses have played a pivotal role in expanding my knowledge and skills, fostering a deep appreciation for the intricacies of these disciplines:",
  imgStart: false,
  img: require("../../images/programming.svg").default,
  alt: "Car",
  dark: false,
  primary: false,
  darkText: true,
};
