export const contactObjOne = {
  id: "contact",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Thank you for visiting my website!",
  headLine: "Contact me",
  description: "Feel free to email me at: tmaz89@hotmail.com ",
  buttonLabel: "Return to top",
  imgStart: true,
  img: require("../../images/talk.svg").default,
  alt: "Car",
  darkText: false,
};
