import React from "react";
import {
  InfoContainer,
  // InfoWrapper,
  // InfoRow,
  // Column1,
  // Column2,
  // TextWrapper,
  // TopLine,
  // Heading,
  // ImgWrap,
  // Img,
} from "./ContactElements";

// import Form from "../Form/Form";

const Contact = ({
  lightBg,
  id,
  // imgStart,
  // topLine,
  // lightText,
  // headLine,
  // img,
  // alt,
}) => {
  return (
    <InfoContainer lightBg={lightBg} id={id}>
      {/* <div data-aos="flip-up">
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headLine}</Heading>
                <Form />
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </div> */}
    </InfoContainer>
  );
};

export default Contact;
